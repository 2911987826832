import { Action } from '@ngrx/store'
import { DesignerInterfaceComponent } from '../designer/designer.interface.component';
import { ActionEx, LayerActionTypes } from './layer-overview.actions';

export function selectedLayerReducer(state: DesignerInterfaceComponent, action: ActionEx){
    switch(action.type){
        case LayerActionTypes.Select:
            return [state, action.payload];
        default:
            return state;
    }
}