import { NgModule } from '@angular/core';
import { Routes,RouterModule } from '@angular/router';
 
import { AuthGuard } from '../auth/auth.guard';
import { ResponseComponent } from '../response/response.component';

const routes: Routes = [   
    {path: 'auth', loadChildren: '../auth/auth.module#AuthModule', canActivate: [AuthGuard]},
    {path: 'register', loadChildren: '../register/register.module#RegisterModule'},
    {path: 'login', loadChildren: '../login/login.module#LoginModule'},
    // {path: 'editor', loadChildren: '../editor/editor.module#EditorModule'},
    // {path: '**', redirectTo: 'home/init'},
    // {path: 'response', component: ResponseComponent},
    {path: '', loadChildren: '../home/home.module#HomeModule'}
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class LazyLoadModule { }
