import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cdk-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorMessage: string = "";
  errorLogin: boolean;
  private formSubmitAttempt: boolean; // {2}
  fragmentType: Observable<string>;
  fragment: string;
  loadingLogin = false;

  constructor(
    private fb: FormBuilder,         // {3}
    private authService: AuthService, // {4}
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({     // {5}
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.fragmentType = this.route.fragment.pipe(map(fragment => fragment || 'None'));
    this.fragmentType.subscribe(data => {
      this.fragment = data;
    })
  }

  isFieldInvalid(field: string) { // {6}
        return (
        (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
        (this.loginForm.get(field).untouched && this.formSubmitAttempt)
        );
    }

    tryFacebookLogin(){
        this.authService.doFacebookLogin()
        .then(res => {
          this.router.navigate(['/auth']);
        })
      }
    
      tryTwitterLogin(){
        this.authService.doTwitterLogin()
        .then(res => {
          this.router.navigate(['/auth']);
        })
      }
    
      tryGoogleLogin(){
        this.authService.doGoogleLogin()
        .then(res => {
          this.router.navigate(['/auth']);
        })
      }
    
      tryLogin(value){
        this.loadingLogin = true;
        this.authService.doLogin(value)
        .then(res => {
          this.errorLogin = false;
          if(this.fragment == "designer"){
            this.router.navigate(['/designer']);
          }else{
            this.loadingLogin = false;
            this.router.navigate(['/auth/dashboard']);
          }
        }, err => {
            console.log(err);
            this.loadingLogin = false;
            this.errorLogin = true;
            switch (err.code) {
              case "auth/user-not-found":
                this.errorMessage = "El usuario no ha sido encontrado";
                break;
              case "auth/wrong-password": 
                this.errorMessage = "La contraseña es incorrecta"; 
                break;
              default:
                this.errorMessage = "El usuario y/o contraseña es incorrecta";                
                break;
            }
        })
      }
    

}
