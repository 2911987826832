import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { UserDesign } from '../models/user_design';
import { User } from '../models/user';
import { Supplier } from '../models/supplier';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userCollection: AngularFirestoreCollection<User>;
  supplierCollection: AngularFirestoreCollection<Supplier>;

  constructor(
    private db: AngularFirestore
  ) { 
    this.userCollection = db.collection('users');
    this.supplierCollection = db.collection('suppliers');
  }

  doRegister(value, roles) {
    return new Promise<any>((resolve, reject) => {
      firebase.functions().httpsCallable('createProfile')({
        uid: new Date().getTime() + '',
        email: value.email,
        emailVerified: true,
        password: value.password,
        displayName: value.name,
        disabled: false
      }).then(res => {
        if(res.data){
          this.db.doc('users/' + res.data.user.uid).set({
            name: value.name,
            birthdate: value.birthdate,
            gender: value.gender,
            email: value.email,
            address: value.address,
            createAt: new Date(),
            extraAddress: value.extraAddress,
            cellphone: value.cellphone,
            product_types: value.product_types,
            photoURL: res.data.user.photoURL,
            uid: res.data.user.uid,
            supplier_id: value.supplier_id,
            roles: roles,
            permission: value.permission,
            type: 'email'
          });
          resolve(res.data);
        }else{
          reject('Error creando el usuario');
        }                                
      }, err => reject(err.message));
    });
  }

  editUser(value: any, roles){
    this.db.doc('users/' + value.uid).set({
      name: value.name,
      birthdate: value.birthdate,
      gender: value.gender,
      email: value.email,      
      address: value.address,
      extraAddress: value.extraAddress,
      cellphone: value.cellphone,
      product_types: value.product_types,
      uid: value.uid,
      roles: roles,
      permission: value.permission
    }, {merge: true});
  }  

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      });
    });
  }

  updateCurrentUser(value) {
    return new Promise<any>((resolve, reject) => {
      const user = firebase.auth().currentUser;
      user.updateProfile({
        displayName: value.name,
        photoURL: value.photoURL
      }).then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  updateDataDelivery(user: any, userData: any){    
    if(!user.update){    
      this.db.doc('users/' + user.uid).set({
        extraAddress: userData.extraAddress,
        address: userData.address,
        cellphone: userData.cellphone,
        update: true
      }, {merge: true});
    }
  }

  updateDataUser(user: User, userData: any): Promise<void>{
    return this.db.doc('users/' + user.uid).set(userData, {merge: true});
  }

  addSupplier(supplier){
    const id = this.db.createId();
    let supplierRef = this.supplierCollection.doc(id);
    supplierRef.set(supplier);
    return id;
  }

  editSupplier(supplier: any){
    let supplierRef = this.supplierCollection.doc(supplier.uid);
    supplierRef.set(supplier, {merge: true});
  }

  getUsers(){
    return this.userCollection.snapshotChanges();
  }

  getSupplier(supplier: string){
    return this.supplierCollection.doc(supplier);
  }
}