import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DesignerInterfaceComponent } from './designer.interface.component';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { LayerSelect } from '../reducers/layer-overview.actions';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
    template: `
    
    <div class="layer_overview {{data.layerType}}_layer" id="layer_{{data.layerID}}_overview" (click)="selectLayer(data.layerID)">
    <div class="layer_preview_container">
    <div fxLayoutAlign="center center" id="layer_preview_{{data.layerID}}" class="layer_preview">
    <div *ngIf="data.layerType == 'text'" id="layer_{{data.layerID}}_previewdiv" style="padding:6%">
    <label id="layer_{{data.layerID}}_preview" style="">{{data.text}}</label>
    </div>
    <div *ngIf="data.layerType == 'image'" id="layer_{{data.layerID}}_previewdiv" style="padding:2%">
    <img [src]="url" id="layer_{{data.layerID}}_preview" class="layer_image_overview"/>
    </div>
    </div>
    </div>
    </div>
      
    `,
    styleUrls: ['./designer.component.scss']
  })
  export class DesignerOverviewComponent implements OnInit, DesignerInterfaceComponent{
    @Input() data: any;
    @Input() layers: any;
    @Input() bounds: any;
    @Input() ngResizable: boolean;
    @Input() preventDefaultEvent: boolean;
    url: SafeResourceUrl;

    constructor(private store: Store<AppState>,
      private sanitizer: DomSanitizer) { 
        // this.store.select('blockchain').subscribe( data => {
        //     if(data)
        //         console.log(data[1]);
        // })
    }

    ngOnInit(){
      if(this.data.layerType == "image"){
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
      }
    }

    ngAfterViewInit() {
      if(this.data.layerType == "image"){
      }else{
          this.initLayerPosition();
      }
    }

    initLayerPosition() {
        if(this.data.is_last_product){
          let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
          jQuery('#layer_' + this.data.layerID + '_preview').addClass(layerStorage.font_family);
          jQuery('#layer_' + this.data.layerID + '_preview').css({'color': this.data.color});
          let shadowProperties = "";
          if(this.data.border_align == "1"){
            shadowProperties = "0 -"+layerStorage.border_width+"px "+layerStorage.border_color+
              ", -"+layerStorage.border_width+"px 0 "+layerStorage.border_color+
              ", 0 -"+layerStorage.border_width+"px "+layerStorage.border_color+
              ", -"+layerStorage.border_width+"px 0 "+layerStorage.border_color;
          }else{
            shadowProperties = ""+layerStorage.border_width+"px 0 "+layerStorage.border_color+
              ", 0 "+layerStorage.border_width+"px "+layerStorage.border_color+
              ", "+layerStorage.border_width+"px 0 "+layerStorage.border_color+
              ", 0 "+layerStorage.border_width+"px "+layerStorage.border_color;
          }
          jQuery('#layer_' + this.data.layerID + '_preview').css({'text-shadow': shadowProperties});
          let rotate = layerStorage.rotate;
          jQuery("#layer_" + this.data.layerID + '_previewdiv').css('transform', 'rotate('+rotate+'deg)');
        }
      }

      selectLayer(layerID) {
        // if (layerID == this.getSelectedLayerID()) {
        //     return;
        // }
        // Act on the layer type.
        var layerType = '';
        switch (layerType) {
            case 'custom_name_number':
                // Handled in NameNumber.js
                break;
    
            default:
                this.deselectLayers();
                jQuery("#layer_preview_" + layerID).addClass("layer_selected");
                let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
                this.store.dispatch(new LayerSelect(layerStorage));    
                break;
        }
    }
    
    deselectLayers() {
        jQuery(".layer_preview").removeClass("layer_selected");
    }
  }