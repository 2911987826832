import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { selectedLayerReducer } from './reducers/layer-overview.reducer';
import { LoginModule } from './login/login.module';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import {AtomSpinnerModule} from 'angular-epic-spinners';
import { DesignerLayerComponent } from './designer/designer-layer.component';
import { DesignerOverviewComponent } from './designer/designer-overview.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { CartComponent } from './cart/cart.component';

@NgModule({
  declarations: [
    AppComponent,
    DesignerLayerComponent,
    DesignerOverviewComponent    
  ],
  exports: [
    
  ],
  imports: [
    BrowserModule,
    LazyLoadModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    CommonModule,
    StoreModule.forRoot({blockchain: selectedLayerReducer}),
    LoginModule,
    AngularDraggableModule
  ],
  providers: [
    
  ],
  entryComponents: [
    DesignerLayerComponent,
    DesignerOverviewComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
