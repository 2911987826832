import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Rx';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  user$: Observable<User>;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private  afAuth:  AngularFireAuth,
    private db: AngularFirestore,
    private router: Router
  ) {
    this.user$ = this.afAuth.authState.switchMap(user => {
      if(user){
        this.loggedIn.next(true);
        return this.db.doc<User>(`users/${user.uid}`).valueChanges()
      }else{
        return new Observable(null);
      }
    })
  }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        this.loggedIn.next(true);
        this.updateDataUser(res.user, 'facebook');        
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        this.loggedIn.next(true);
        this.updateDataUser(res.user, 'twitter');
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        this.loggedIn.next(true);
        this.updateDataUser(res.user, 'google');    
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  doLogin(value) {
    return firebase.auth().signInWithEmailAndPassword(value.email, value.password)
      .then((res: any) => {
        this.loggedIn.next(true);
        // this.updateDataUser(res.user);
      });
  }

  doLoginClient(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password)
      .then(res => {
        this.loggedIn.next(true);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.auth.signOut();
        this.loggedIn.next(false);
        resolve();
      } else {
        reject();
      }
    });
  }

  private updateDataUser(user: any, type: string){
    const userRef: AngularFirestoreDocument<any> = this.db.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      name: user.displayName,
      roles: {client: true},
      update: false,
      createAt: new Date(),
      type: type
    }
    userRef.set(data, {merge: true});
  }

  canCustomize(user: User): boolean{
    const allowed = ['client'];
    return this.checkAuthorization(user, allowed);
  }

  canSell(user: User): boolean{
    const allowed = ['seller'];
    return this.checkAuthorization(user, allowed);
  }

  canDashboard(user: User): boolean{
    const allowed = ['seller', 'supplier', 'designer', 'modiste', 'admin'];
    return this.checkAuthorization(user, allowed);
  }

  canAdmin(user: User): boolean{
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  private checkAuthorization(user: User, allowedRoles: string[]): boolean{
    if(!user) return false;
    for(const role of allowedRoles){
      if(user.roles[role]){
        return true;
      }
    }
    return false;
  }
}