import { Action } from '@ngrx/store';
export enum LayerActionTypes {
    Select = '[Layer Component] Select',
    Remove = '[Layer Component] Remove'
  }

  export class ActionEx implements Action {
    readonly type;
    payload: any;
  }

  export class LayerSelect implements ActionEx {
    readonly type = LayerActionTypes.Select;
    constructor(public payload: any) {
    }
  }
  export class LayerRemove implements ActionEx {
    readonly type = LayerActionTypes.Remove;
    constructor(public payload: any) {
    }
  }