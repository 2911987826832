import { Component, Input, HostListener, SimpleChanges } from '@angular/core';
import { DesignerInterfaceComponent } from './designer.interface.component';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { LayerSelect } from '../reducers/layer-overview.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { AngularResizableDirective } from 'angular2-draggable';

@Component({
    template: `
    
      <div id="layer_{{data.layerID}}" style="touch-action: none;" [ngResizable]="ngResizable" [rzHandles]="handles"
       [ngDraggable]="draggable" [bounds]="bounds" [inBounds]="inBounds"
      (endOffset)="onMoveEnd($event)" [preventDefaultEvent]="preventDefaultEvent" 
      (started)="onMovingStart($event)" (movingOffset)="onMoving($event)" (rzStart)="onResizeStart($event)" (rzResizing)="onResizing($event)"> 
      <p>
        <label *ngIf="data.layerType == 'text'" id="layer_{{data.layerID}}_text" style="cursor: move;">
          {{data.text}}
        </label>
        <img *ngIf="data.layerType == 'image'" [src]="url" id="layer_{{data.layerID}}_image" class="layer_image"/>    
        </p>
      </div>
      
    `,
    styleUrls: ['./designer.component.scss']
  })
  export class DesignerLayerComponent implements DesignerInterfaceComponent{
    @Input() data: any;
    @Input() layers: any;
    @Input() bounds: any;
    @Input() ngResizable: boolean;
    @Input() preventDefaultEvent: boolean;
    url: SafeResourceUrl;
    layerHeight = 0;
    draggable = true;
    handles = "n,s,e,w,se,sw,ne,nw";
    inBounds = true;

    constructor(private sanitizer: DomSanitizer,
      private store: Store<AppState>){

    }

    ngOnInit(){
      let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
        var layerType = layerStorage.layerType;
        if (layerType == "image") {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
        }
    }

    ngAfterViewInit() {
      this.initLayerPosition();      
    }

    initLayerPosition() {
      if(this.data.is_last_product){
        let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
        jQuery('#layer_' + this.data.layerID + '_text').addClass(layerStorage.font_family).css({'font-size': layerStorage.font_size + 'pt'});
        jQuery('#layer_' + this.data.layerID + '_text').css({'color': this.data.color});
        let shadowProperties = "";
        if(this.data.border_align == "1"){
          shadowProperties = "0 -"+layerStorage.border_width+"px "+layerStorage.border_color+
            ", -"+layerStorage.border_width+"px 0 "+layerStorage.border_color+
            ", 0 -"+layerStorage.border_width+"px "+layerStorage.border_color+
            ", -"+layerStorage.border_width+"px 0 "+layerStorage.border_color;
        }else{
          shadowProperties = ""+layerStorage.border_width+"px 0 "+layerStorage.border_color+
            ", 0 "+layerStorage.border_width+"px "+layerStorage.border_color+
            ", "+layerStorage.border_width+"px 0 "+layerStorage.border_color+
            ", 0 "+layerStorage.border_width+"px "+layerStorage.border_color;
        }
        jQuery('#layer_' + this.data.layerID + '_text').css({'text-shadow': shadowProperties});
        let rotate = layerStorage.rotate;
        jQuery("#layer_" + this.data.layerID).css('transform', 'translate('+layerStorage.translatex+'px, '+layerStorage.translatey+'px) rotate('+rotate+'deg)');
        if(this.data.layerType == "text"){
          jQuery("#layer_" + this.data.layerID + "_text").css({'font-size': layerStorage.font_size + 'pt', 'line-height': layerStorage.line_height + 'px'});
          jQuery("#layer_" + this.data.layerID + "_text").addClass(layerStorage.font_family);
        }else if(this.data.layerType == "image"){
          jQuery("#layer_" + this.data.layerID + "_image").css({ width: layerStorage.width + "px", height: layerStorage.height + "px"});
        }
        var layer = jQuery("#layer_" + this.data.layerID);
        let xyz = {
          left: layerStorage.x + "px",
          top: layerStorage.y + "px",
          "z-index": layerStorage.position
        }
        layer.css(xyz);
      }else{
        let layerID = this.data.layerID;
        let layerStorage = JSON.parse(localStorage.getItem(layerID));
        var alignment = layerStorage.align;
        var layerType = layerStorage.layerType;
    
        this.alignLayer(layerID, alignment);
        if (layerType == "text") {
            // Unset dimensions that are set server-side to allow correct position
            jQuery("#layer_" + layerID).css({ width: "auto", height: "auto" });
        }
        if (layerType == "image") {
          // Unset dimensions that are set server-side to allow correct position          
          var layerImage = jQuery("#surface").width() / 3;
          jQuery("#layer_" + layerID + "_image").css({ width: layerImage + "px"});
          let layerStorage = JSON.parse(localStorage.getItem(layerID));
          layerStorage.font_size = layerImage;
          localStorage.setItem(layerID, JSON.stringify(layerStorage));
        }
        
      }
    }

    alignLayer(layerID, alignment) {
      var layer = jQuery("#layer_" + layerID);
      var layerWidth = layer.width();
      var layerHeight = layer.height();
      var surface = jQuery("#surface");
      var surfaceWidth = surface.width() / 2;
      var surfaceHeight = surface.height();
      var x = 0;
      var y = 0;
      switch (alignment) {
          case "Top-Left":
          case "Middle-Left":
          case "Bottom-Left":
              break;
          case "Top-Center":
          case "Middle-Center":
          case "Bottom-Center":
          case "Third-Center":
              x = surfaceWidth / 3;
              break;
          case "Top-Right":
          case "Middle-Right":
          case "Bottom-Right":
              x = surfaceWidth - layerWidth;
              break;
      }
  
      switch (alignment) {
          case "Top-Left":
          case "Top-Center":
          case "Top-Right":
              break;
          case "Middle-Left":
          case "Middle-Center":
          case "Middle-Right":
              y = surfaceHeight / 3;
              break;
          case "Bottom-Left":
          case "Bottom-Center":
          case "Bottom-Right":
              y = surfaceHeight - layerHeight;
              break;
          case "Third-Center":
              y = surfaceHeight / 3;
              break;
      }
      let layerStorage = JSON.parse(localStorage.getItem(layerID));      
      layerStorage.x = x;
      layerStorage.y = y;      
      let xyz = {
        left: x + "px",
        top: y + "px",
        "z-index": this.data.order
      }
      layer.css(xyz);      
      layerStorage.position = this.data.order;
      localStorage.setItem(layerID, JSON.stringify(layerStorage));
    }

    selectLayer(layerID) {      
      // Act on the layer type.      
      let layerStorage = JSON.parse(localStorage.getItem(layerID));
      jQuery("#layer_" + layerID).css('transform', 'translate('+layerStorage.translatex+'px, '+layerStorage.translatey+'px) rotate('+layerStorage.rotate+'deg)');
      var layerType = '';
      switch (layerType) {
          case 'custom_name_number':
              // Handled in NameNumber.js
              break;
  
          default:
              this.deselectLayers();
              jQuery("#layer_preview_" + layerID).addClass("layer_selected");
              let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
              this.store.dispatch(new LayerSelect(layerStorage));    
              break;
      }
    }

    deselectLayers() {
      jQuery(".layer_preview").removeClass("layer_selected");
    }

    onMoveEnd(event) {
      let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
      let rotate = layerStorage.rotate;
      jQuery("#layer_" + this.data.layerID).css('transform', 'translate('+event.x+'px, '+event.y+'px) rotate('+rotate+'deg)');
      layerStorage.translatex = event.x;
      layerStorage.translatey = event.y;
      localStorage.setItem(this.data.layerID + '', JSON.stringify(layerStorage));
    }

    @HostListener('mousedown', ['$event'])
    @HostListener('touchmove', ['$event'])
    onMoving(event: MouseEvent | TouchEvent){
    
    }
    onMovingStart(event: MouseEvent | TouchEvent){ 
      let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
      var layerType = layerStorage.layerType;
      switch (layerType) {
          case 'custom_name_number':
              // Handled in NameNumber.js
              break;
  
          default:
              this.deselectLayers();
              jQuery("#layer_preview_" + this.data.layerID).addClass("layer_selected");
              let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
              this.store.dispatch(new LayerSelect(layerStorage));    
              break;
      }
      // jQuery("body").on('drag dragstart dragend dragleave dragenter dragover drop',function(e){            
      //   console.log('ad', e);
      //   e.preventDefault();
      //   e.stopPropagation();
      //   e.originalEvent.preventDefault();
      //   e.originalEvent.stopPropagation();
      // });
      // jQuery('#layer_' + this.data.layerID).on('touchstart', function(e){
      //   console.log('event drag',e);
      //   e.stopPropagation();
      //   e.preventDefault();
      //   return false;
      // })
    }

    onResizeStart(event){
      const wfont = jQuery("#layer_" + this.data.layerID + "_text").css('font-size');
      const point_font = parseInt(wfont) * 72 / 96;
      this.layerHeight = parseInt(event.size.height+'') / point_font;;
    }

    onResizing(event){
      let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
      if (layerStorage.layerType == "text") {
        const delta = parseInt(event.size.height) / parseInt(this.layerHeight+'');
        jQuery("#layer_" + this.data.layerID + "_text").css('font-size', delta + 'pt');
        layerStorage.font_size = event.size.font_size;
        localStorage.setItem(this.data.layerID + '', JSON.stringify(layerStorage));
      } else if (layerStorage.layerType == "image") {     
        jQuery("#layer_" + this.data.layerID + "_image").css({'width': event.size.width + 'px', 'height': event.size.height});
        layerStorage.width = event.size.width;
        layerStorage.height = event.size.height;
        localStorage.setItem(this.data.layerID + '', JSON.stringify(layerStorage));
      }      
    }

    onReset(block: AngularResizableDirective) {
      block.resetSize();
      let { size, position } = block.getStatus();
      let layerStorage = JSON.parse(localStorage.getItem(this.data.layerID));
      jQuery("#layer_" + this.data.layerID + "_image").css({'width': size.width + 'px', 'height': size.height});
      layerStorage.width = size.width;
      layerStorage.height = size.height;
      localStorage.setItem(this.data.layerID + '', JSON.stringify(layerStorage));      
    }
  }