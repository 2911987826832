import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef } from '@angular/material';
import { UserService } from '../../services/user.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'cdk-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['../login.component.scss']
})

export class LoginModalComponent implements OnInit {
  loginForm: FormGroup;
  errorMessage: string = ''; 
  errorLogin: boolean;              // {1}
  private formSubmitAttempt: boolean; // {2}
  dataUser: any = {};
  loadingLogin: boolean;
  showRegisterForm: boolean;
  @ViewChild("validateEmail", { static: false }) validateEmail: ElementRef;
  fieldsRegister = ['name', 'gender', 'birthdate'];

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    public dialogRef: MatDialogRef<LoginModalComponent>
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({     // {5}
      email: ['', Validators.required],
      password: ['', Validators.minLength(6)],
      name: ['', Validators.required],
      gender: ['', Validators.required],
      birthdate: ['', Validators.required],
      conditions: ['', Validators.required],
      data: ['']
    });
  }

  isFieldInvalid(field: string) { // {6}
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  tryFacebookLogin() {
    this.showRegisterForm = false;
    this.loadingLogin = true;
    this.authService.doFacebookLogin()
      .then(res => {
        this.loadingLogin = false;
        this.dialogRef.close(res);
      })
  }

  tryTwitterLogin() {
    this.authService.doTwitterLogin()
      .then(res => {
        this.loadingLogin = false;
        this.dialogRef.close(res);
      })
  }

  tryGoogleLogin() {
    this.showRegisterForm = false;
    this.loadingLogin = true;
    this.authService.doGoogleLogin()
      .then(res => {
        this.loadingLogin = false;
        this.dialogRef.close(res);
      })
  }

  tryLogin(value) {
    this.showRegisterForm = false;
    this.loadingLogin = true;
    this.authService.doLoginClient(value)
      .then(res => {
        this.loadingLogin = false;
        this.dialogRef.close(res);
      }, err => {
        console.log(err);
        this.loadingLogin = false;
        this.errorLogin = true;
        switch (err.code) {
          case "auth/user-not-found":
            this.errorMessage = "El usuario no ha sido encontrado";
            break;
          case "auth/wrong-password": 
            this.errorMessage = "La contraseña es incorrecta"; 
            break;
          default:
            this.errorMessage = "El usuario y/o contraseña es incorrecta";                
            break;
        }
      })
  }

  showRegister(active: boolean) {
    this.showRegisterForm = active;
    for (let field of this.fieldsRegister) {
      if (active) {
        this.loginForm.get(field).setValidators(Validators.required);
      } else {
        this.loginForm.get(field).setValidators(null);
      }
    }
  }

  doRegister(value: any) {
    this.validateEmail.nativeElement.innerHTML = "";
    if (this.loginForm.valid) {
      const roles = { client: true }
      value.address = "";
      value.extraAddress = "";
      value.cellphone = "";
      value.product_types = [];
      value.permission = [];
      value.supplier_id = "";
      this.userService.doRegister(value, roles).then(res => {
        this.showRegisterForm = false;
        this.authService.doLogin(value)
          .then(res => {
            this.loadingLogin = false;
            this.dialogRef.close(res);
          }, err => {
            console.log(err);
            this.loadingLogin = false;
            this.errorMessage = err.message;
          })
      }, err => {
        switch (err.code) {
          case "auth/email-already-in-use":
            this.validateEmail.nativeElement.innerHTML = "El correo electrónico ya se encuentra registrado";
          default:
            this.validateEmail.nativeElement.innerHTML = "";
        }
        this.loadingLogin = false;
        this.errorMessage = err.message;
      })
    } else {
      Object.keys(this.loginForm.controls).forEach(field => { // {1}                
        const control = this.loginForm.get(field);
        control.markAsTouched({ onlySelf: true });       // {3}
      });
    }
  }

}